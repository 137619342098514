/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("typeface-inter")

const React = require("react")
const { CartProvider } = require("./src/context/CartContext")
const { CampaignProvider } = require("./src/context/CampaignContext")

exports.wrapRootElement = ({ element }) => {
  return (
    <CampaignProvider>
      <CartProvider>{element}</CartProvider>
    </CampaignProvider>
  )
}
