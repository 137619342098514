import React, { useState } from "react"

export const CampaignContext = React.createContext()

export const CampaignProvider = (props) => {
  const [campaign, setCampaign] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("fundraisify-campaign")
      : ""
  )

  const updateCampaign = (campaign) => {
    setCampaign(campaign)
    localStorage.setItem("fundraisify-campaign", campaign)
  }

  return (
    <CampaignContext.Provider
      value={{
        campaign,
        updateCampaign,
      }}
    >
      {props.children}
    </CampaignContext.Provider>
  )
}
