import React, { useState } from "react"

export const CartContext = React.createContext()

export const CartProvider = (props) => {
  const [cartId, setCartId] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("fundraisify-cart")
      : ""
  )

  const updateCartId = (cartId) => {
    setCartId(cartId)
    localStorage.setItem("fundraisify-cart", cartId)
  }

  return (
    <CartContext.Provider
      value={{
        cartId,
        updateCartId,
      }}
    >
      {props.children}
    </CartContext.Provider>
  )
}
